const members = [
  {
    id: "148",
    name: "Nikillan Rajesh",
    nickname: "niKILLan",
    designation: "Team Manager",
    facebook: null,
    mail: "mailto:nikillanrajesh@gmail.com",
    linkedin: "https://www.linkedin.com/in/nikillan-rajesh/",
    github: "https://github.com/Nikillanr"
  },
  {
    id: "147",
    name: "Gaurav Pradeep",
    nickname: "9.81-SPOT",
    designation: "Technical Head",
    facebook: null,
    mail: "mailto:gauravpradeep2004@gmail.com",
    linkedin: "https://www.linkedin.com/in/gaurav-pradeep-352253255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null
  },
  {
    id: "142",
    name: "Nirbhay Aher",
    nickname: "nOP-AMP",
    designation: "Research and Development Head",
    facebook: null,
    mail: "mailto:nirbhayaher@gmail.com",
    linkedin: "https://www.linkedin.com/in/nirbhay-aher-338708156/",
    github: null
  },
  {
    id: "149",
    name: "Aaryan Takayuki Panigrahi",
    nickname: "TAKAYUKI SIN(TT)",
    designation: "AI Head",
    facebook: null,
    mail: "mailto:aaryan.t.panigrahi@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "150",
    name: "Naman Kumar",
    nickname: "Nah man",
    designation: "Sensing and Automation Head",
    facebook: null,
    mail: "mailto:naman.kmr246@gmail.com",
    linkedin: "https://www.linkedin.com/in/naman-kumar-374b6a2a9/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/00naman"
  },
  {
    id: "151",
    name: "Manjyot Saini",
    nickname: "INSAIN",
    designation: "Management Head",
    facebook: null,
    mail: "mailto:manjyotsaini1911@gmail.com",
    linkedin: "https://www.linkedin.com/in/manjyot-saini-559a7b271?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BihswaCCJRcGsZS0ecRcysA%3D%3D",
    github: null
  },
  {
    id: "152",
    name: "Gautham D Nair",
    nickname: "Got Em",
    designation: "Mechanical Head",
    facebook: null,
    mail: "mailto:gauthamdinilnair@gmail.com",
    linkedin: "https://www.linkedin.com/in/gautham-d-nair-bb230b274/?originalSubdomain=in",
    github: null
  },
  {
    id: "128",
    name: "Aditi Milind Joshi",
    nickname: "ADU DIDI",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:aditij0205@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "129",
    name: "Anirudh Agrawal",
    nickname: "DADDY ANIRUDH",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:anirudhagrawal6778@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "130",
    name: "David Jijo",
    nickname: "Belt-man",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:thisisdavidjijo@gmail.com",
    linkedin: "https://www.linkedin.com/in/david-jijo-a17a32288/",
    github: "https://github.com/AlliedLens"
  },
  {
    id: "131",
    name: "Subham Patra",
    nickname: "SUBHU DARLIN'",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:patrasubham2004@gmail.com",
    linkedin: "https://www.linkedin.com/in/subham-patra-94938526b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/AlphaGotReal"
  },
  {
    id: "132",
    name: "Vaishnavi Kulkarni",
    nickname: "ADITI",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:vaishnavik002@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "133",
    name: "Anaadhi Mongia",
    nickname: "ANAAGIN",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:anaadhimongia9+mns@gmail.com",
    linkedin: "https://www.linkedin.com/in/anaadhi-mongia-5890bb210/",
    github: "https://github.com/anaadhi/"
  },
  {
    id: "134",
    name: "Animesh Patil",
    nickname: "GOOS",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:animeshmanikpatil@gmail.com",
    linkedin: "https://www.linkedin.com/in/animesh-patil-248993266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/animeshpatil"
  },
  {
    id: "135",
    name: "Natasha Gonsalves",
    nickname: "NATZ",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:tashagonsalves0418@gmail.com",
    linkedin: "https://www.linkedin.com/in/natasha-gonsalves-32647529b/",
    github: null
  },
  {
    id: "136",
    name: "Siddharth Mathkar",
    nickname: "MAT KAR",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:siddharth.mathkar7@gmail.com",
    linkedin: "www.linkedin.com/in/siddharth-mathkar-314737246",
    github: null
  },
  {
    id: "137",
    name: "Sparsh Srivastava",
    nickname: "SPARK",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:sparsh1264@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "138",
    name: "Vansh Yadav",
    nickname: "VAN$",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:vanshyadav@live.com",
    linkedin: "https://www.linkedin.com/in/vansh-yadav-a7707121a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/norsechurros"
  },
  {
    id: "139",
    name: "Anurag Raut",
    nickname: "ELAI",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:anuragelai@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "141",
    name: "Jaival Shah",
    nickname: "ORCUS",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:jaivalhshah@gmail.com",
    linkedin: "https://www.linkedin.com/in/jaival-shah-6a0310274?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null
  },
  {
    id: "143",
    name: "Zain Hussain",
    nickname: "ZED",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:zainh1402@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "144",
    name: "Chinmayi Bhat",
    nickname: "CHIPMUNK",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:chinmayibhat.in@gmail.com",
    linkedin: "https://in.linkedin.com/in/chinmayi-bhat-986a63246",
    github: null
  },
  {
    id: "145",
    name: "Diya Nisar",
    nickname: "DIYANASAUR",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:diya.nisar777@gmail.com",
    linkedin: "https://www.linkedin.com/in/diya-nisar-495375207/",
    github: null
  },
  {
    id: "146",
    name: "Eric B John",
    nickname: "eRIC ROLL",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:ericbinujohn@gmail.com",
    linkedin: "https://www.linkedin.com/in/eric-b-john46/",
    github: "https://github.com/Eric-B-J"
  },
  {
    id: "153",
    name: "Asmit Paul",
    nickname: "AS*MIT",
    designation: "AI Member",
    facebook: null,
    mail: "mailto:hiasmitpaul@gmail.com",
    linkedin: null,
    github: "http://github.com/asmit-mit"
  },
  {
    id: "154",
    name: "Janak Shah",
    nickname: ".js",
    designation: "AI Member",
    facebook: null,
    mail: "mailto:janak.sh41@gmail.com",
    linkedin: "https://www.linkedin.com/in/janaks1/",
    github: "https://github.com/codegallivant"
  },
  {
    id: "156",
    name: "Mrigaank Mouli",
    nickname: "ZER0º",
    designation: "AI Member",
    facebook: null,
    mail: "mailto:sufy1707@gmail.com",
    linkedin: "https://in.linkedin.com/in/mrigaank-mouli-676120287",
    github: null
  },
  {
    id: "155",
    name: "Om Kumar",
    nickname: "0K",
    designation: "AI Member",
    facebook: null,
    mail: "mailto:okhere21@gmail.com",
    linkedin: "https://www.linkedin.com/in/om-kumar-75517926a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/W-OK-E"
  },
  {
    id: "157",
    name: "Tanmay Singh Bains",
    nickname: "GAINS",
    designation: "AI Member",
    facebook: null,
    mail: "mailto:tanmaybains.2005@gmail.com",
    linkedin: "https://www.linkedin.com/in/tanmay-bains-677576281",
    github: "https://github.com/TanmayBnz"
  },
  {
    id: "158",
    name: "Vivek Singh",
    nickname: "VIVAAD",
    designation: "AI Member",
    facebook: null,
    mail: "",
    linkedin: null,
    github: null
  },
  {
    id: "159",
    name: "Davasam Karthikeya",
    nickname: "Davawesome",
    designation: "Sensing and Automation Member",
    facebook: null,
    mail: "mailto:davasamkarthikeya@gmail.com",
    linkedin: "https://www.linkedin.com/in/davasam-karthikeya",
    github: "https://github.com/Karthikeya819"
  },
  {
    id: "160",
    name: "Kartikeya Singh",
    nickname: "Karthicc",
    designation: "Sensing and Automation Member",
    facebook: null,
    mail: "",
    linkedin: null,
    github: null
  },
  {
    id: "161",
    name: "Kaustubh Pandey",
    nickname: "Buck-LOL",
    designation: "Sensing and Automation Member",
    facebook: null,
    mail: "mailto:kaustubhofficial.kp@gmail.com",
    linkedin: "https://www.linkedin.com/in/kaustubh-pandey-b42082218/?originalSubdomain=in",
    github: "https://github.com/KPkaustubhKP"
  },
  {
    id: "162",
    name: "Sachin Sushil",
    nickname: "shawarma",
    designation: "Sensing and Automation Member",
    facebook: null,
    mail: "mailto:sachinctl@protonmail.com",
    linkedin: "https://in.linkedin.com/in/sachin-sushil",
    github: "https://github.com/schctl"
  },
  {
    id: "163",
    name: "Sudeep Sharma",
    nickname: "WattraSudip",
    designation: "Sensing and Automation Member",
    facebook: null,
    mail: "mailto:sudeep220604@gmail.com",
    linkedin: "https://www.linkedin.com/in/sudeep-sharma-8a4624248?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null
  },
  {
    id: "164",
    name: "Aadya Jha",
    nickname: "MOTORS",
    designation: "Mechanical Member",
    facebook: null,
    mail: "mailto:Aadyaj0305@gmail.com",
    linkedin: null,
    github: "https://github.com/Aadya03"
  },
  {
    id: "165",
    name: "Anvith Choudhary Jasti",
    nickname: "AN-Sys",
    designation: "Mechanical Member",
    facebook: null,
    mail: "",
    linkedin: null,
    github: null
  },
  {
    id: "166",
    name: "Farshad Hormuz Lavangia",
    nickname: "LANKENSTEIN",
    designation: "Mechanical Member",
    facebook: null,
    mail: "",
    linkedin: null,
    github: null
  },
  {
    id: "167",
    name: "Mahua Singh",
    nickname: "GRAVITY",
    designation: "Mechanical Member",
    facebook: null,
    mail: "mailto:mahuasingh91@gmail.com",
    linkedin: "https://www.linkedin.com/in/mahua-singh",
    github: null
  },
  {
    id: "168",
    name: "Mohit Nambiar",
    nickname: "6 FT. UNDER",
    designation: "Mechanical Member",
    facebook: null,
    mail: "mailto:mohitnambiar80@gmail.com",
    linkedin: "https://www.linkedin.com/in/mohit-nambiar-b8b6241bb?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null
  },
  {
    id: "169",
    name: "Aditi Jain",
    nickname: "adeity",
    designation: "Management Member",
    facebook: null,
    mail: "mailto:jainaditi2005@gmail.com",
    linkedin: "https://www.linkedin.com/in/aditijain11/",
    github: "https://github.com/aditi116"
  },
  {
    id: "170",
    name: "Ashwin Modey",
    nickname: "smASH",
    designation: "Management Member",
    facebook: null,
    mail: "mailto:ashwinmodey05@gmail.com",
    linkedin: "https://www.linkedin.com/in/ashwin-modey-2274b220b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null
  }
];

export default members;
